<template>
  <b-container no-gutters class="settings-coa-coas px-0">
    <div class="bordered-table mt-3">
      <b-row no-gutters>
        <b-col>
          <h2>{{ $t(translationPath + 'title') }}</h2>
        </b-col>
        <b-col v-if="isLoaded && coas.length < coasAmountLimit">
          <b-link class="btn btn-xs btn-secondary float-right mb-2 mb-md-0" @click.prevent="onBtnAddCoaClick">
            <i class="flaticon stroke plus"></i>
            {{ $t(translationPath + "table.buttons.create_coa") }}
          </b-link>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <div v-html="$t(translationPath + 'description')"></div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <template v-if="isFormEditShown">
            <finance-settings show-cancel-button :action-create="isInCreateCoaAction" :coa-id="useCoaId" @saved="onSaved" @canceled="isFormEditShown = false" />
          </template>
          <template v-else>
            <b-table
              :items="coas"
              :fields="tableFields"
              :busy="!isLoaded"
              stacked="md"
              class="spirecta-simple-table mb-0"
              responsive
              striped
              show-empty
            >
              <template v-slot:table-busy>
                <loader />
              </template>

              <template v-slot:cell(networth_account_id)="row">
                <template v-if="row.item.networth_account">
                  {{ row.item.networth_account.title }}
                </template>
              </template>

              <template v-slot:cell(income_observation_account_id)="row">
                <template v-if="row.item.income_observation_account">
                  {{ row.item.income_observation_account.title }}
                </template>
              </template>

              <template v-slot:cell(expense_observation_account_id)="row">
                <template v-if="row.item.expense_observation_account">
                  {{ row.item.expense_observation_account.title }}
                </template>
              </template>

              <template v-slot:cell(transfer_observation_account_id)="row">
                <template v-if="row.item.transfer_observation_account">
                  {{ row.item.transfer_observation_account.title }}
                </template>
              </template>

              <template v-slot:cell(default_budget_id)="row">
                <template v-if="row.item.default_budget">
                  {{ row.item.default_budget.title }}
                </template>
              </template>

              <template v-slot:cell(title)="row">
                {{ row.value }}
                <b-badge v-if="row.item.id === currentCOA.id" variant="info">{{ $t(translationPath + 'table.badges.active') }}</b-badge>
              </template>

              <template v-slot:cell(actions)="row">
                <div class="text-right">
                <b-button
                  v-if="row.item.id !== currentCOA.id"
                  @click="onCoaActivateClick(row.item)"
                  :title="$t(translationPath + 'table.buttons.activate')"
                  class="plain-btn action-button mr-2"
                >
                  <i class="flaticon solid star-2 text-info"></i>
                </b-button>
                <b-button
                  @click="onCoaEditClick(row.item)"
                  :title="$t('common.edit')"
                  class="plain-btn action-button mr-2"
                >
                  <i class="flaticon solid edit-3 text-success"></i>
                </b-button>
                <b-button
                  v-if="coas.length < coasAmountLimit"
                  @click="onCoaCopyDeepClick(row.item)"
                  :title="$t(translationPath + 'table.buttons.copy_deep')"
                  class="plain-btn action-button mr-2"
                >
                  <i class="flaticon solid copy-4 text-success"></i>
                </b-button>
                <b-button
                  @click="onCoaResetClick(row.item)"
                  :title="$t(translationPath + 'table.buttons.reset')"
                  class="plain-btn action-button"
                  :class="{ 'mr-2': row.item.id !== currentCOA.id }"
                >
                  <i class="flaticon solid copy-1 text-danger"></i>
                </b-button>
                <b-button
                  v-if="row.item.id !== currentCOA.id"
                  @click="onCoaDeleteClick(row.item)"
                  :title="$t('common.delete')"
                  class="plain-btn action-button"
                >
                  <i class="flaticon solid trash-3 text-danger"></i>
                </b-button>
                </div>
              </template>
            </b-table>
          </template>
        </b-col>
      </b-row>
    </div>

    <delete-modal
      @on-delete-cancel="onCoaDeleteCancel"
      @on-delete-confirm="onCoaDeleteConfirm"
      ref="DeleteCoaModal"
      :heading="$t(translationPath + 'modal_delete.title', { title: this.coaToDelete ? this.coaToDelete.title : '' })"
      :message="$t(translationPath + 'modal_delete.text')"
    />

    <confirm-modal
      ref="ConfirmCopyDeepModal"
      :title="$t(translationPath + 'modal_copy_deep.title', { title: this.coaToCopy ? this.coaToCopy.title : '' })"
      :message="$t(translationPath + 'modal_copy_deep.text', { title: this.coaToCopy ? this.coaToCopy.title : '' })"
      :yes-caption="$t(translationPath + 'modal_copy_deep.yes_caption')"
      @not-confirmed="onCoaCopyDeepCancel"
      @confirmed="onCoaCopyDeepConfirmed"
    />

    <delete-coa-modal ref="ResetCoaModal" @confirmed="onCoaResetConfirmed" @cancelled="onCoaResetCancelled" />
  </b-container>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import { mapState, mapActions } from 'vuex'
import FinanceSettings from './FinanceSettings'
import DeleteModal from '@/components/modals/DeleteModal'
import ConfirmModal from '@/components/modals/ConfirmModal'
import DeleteCoaModal from '@/components/modals/DeleteCoaModal'

export default {
  name: 'ChartsOfAccounts',
  components: { Loader, FinanceSettings, DeleteModal, ConfirmModal, DeleteCoaModal },
  data () {
    return {
      translationPath: 'settings.tab_charts_of_accounts.',
      coas: [],
      isLoaded: false,
      isFormEditShown: false,
      isInCreateCoaAction: false,
      useCoaId: 0,
      coaToDelete: null,
      coaToCopy: null,
      coaToReset: null,
      coasAmountLimit: 3
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    tableFields () {
      return [
        { key: 'title', label: this.$t('common.title') },
        /* { key: 'currency_iso', label: this.$t(this.translationPath + 'table.currency_iso') },
        { key: 'networth_account_id', label: this.$t(this.translationPath + 'table.networth_account') },
        { key: 'income_observation_account_id', label: this.$t(this.translationPath + 'table.income_observation_account') },
        { key: 'expense_observation_account_id', label: this.$t(this.translationPath + 'table.expense_observation_account') },
        { key: 'transfer_observation_account_id', label: this.$t(this.translationPath + 'table.transfer_observation_account') },
        { key: 'default_budget_id', label: this.$t(this.translationPath + 'table.default_budget') }, */
        { key: 'actions', label: '' }
      ]
    }
  },
  created () {
    this.loadCoas().then(() => {
      this.isLoaded = true
    })
  },
  methods: {
    ...mapActions('user', ['loadCurrentCOA']),
    async loadCoas () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts`)
          .then(response => {
            this.coas = response.data.data
            resolve(this.coas)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    onBtnAddCoaClick () {
      this.isInCreateCoaAction = true
      this.useCoaId = 0
      this.isFormEditShown = !this.isFormEditShown
    },
    onCoaActivateClick (item) {
      axios.put(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/activate/${item.id}`)
        .then(response => {
          this.$bvToast.toast(
            this.$t('settings.tab_chart_of_accounts.coa_activated', { title: item.title }),
            {
              title: this.$t(this.translationPath + 'title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
          this.loadCurrentCOA()
            .then(() => {
              this.$router.go()
            })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(
            this.$t('settings.tab_chart_of_accounts.coa_not_activated'),
            {
              title: this.$t(this.translationPath + 'title'),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
        })
    },
    onCoaEditClick (item) {
      this.isInCreateCoaAction = false
      this.isFormEditShown = true
      this.useCoaId = item.id
    },
    onCoaCopyDeepClick (item) {
      this.coaToCopy = item
      this.$refs.ConfirmCopyDeepModal.show()
    },
    onCoaCopyDeepCancel () {
      this.coaToCopy = null
    },
    onCoaCopyDeepConfirmed () {
      this.$refs.ConfirmCopyDeepModal.hide()
      this.$bvToast.toast(
        this.$t(this.translationPath + 'toast.copy_deep.text_processing', { title: this.coaToCopy.title }),
        {
          title: this.$t(this.translationPath + 'toast.copy_deep.title'),
          variant: 'info',
          solid: true,
          'auto-hide-delay': 3000
        }
      )

      axios.post(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/copy-deep/${this.coaToCopy.id}`)
        .then(response => {
          this.$bvToast.toast(
            this.$t(this.translationPath + 'toast.copy_deep.text_success', { title: this.coaToCopy.title }),
            {
              title: this.$t(this.translationPath + 'toast.copy_deep.title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
          this.loadCoas()
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(
            this.$t(this.translationPath + 'toast.copy_deep.text_fail', { title: this.coaToCopy.title }),
            {
              title: this.$t(this.translationPath + 'toast.copy_deep.title'),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
        })
    },
    onCoaDeleteClick (item) {
      this.coaToDelete = item
      this.$refs.DeleteCoaModal.show()
    },
    onCoaDeleteCancel () {
      this.coaToDelete = null
    },
    onCoaDeleteConfirm () {
      axios.delete(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/${this.coaToDelete.id}`)
        .then(response => {
          this.$bvToast.toast(
            this.$t('settings.tab_charts_of_accounts.toast.coa_delete_success', { title: this.coaToDelete.title }),
            {
              title: this.$t(this.translationPath + 'title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
          this.loadCoas()
        })
        .catch(err => {
          if (err.response.status === 406) {
            this.$bvToast.toast(
              this.$t('settings.tab_charts_of_accounts.toast.coa_not_empty', { title: this.coaToDelete.title }),
              {
                title: this.$t(this.translationPath + 'title'),
                variant: 'danger',
                solid: true,
                'auto-hide-delay': 7000
              }
            )
          } else if (err.response.status === 404) {
            this.$bvToast.toast(
              this.$t('settings.tab_charts_of_accounts.toast.coa_not_found'),
              {
                title: this.$t(this.translationPath + 'title'),
                variant: 'danger',
                solid: true,
                'auto-hide-delay': 3000
              }
            )
          } else {
            this.$bvToast.toast(
              this.$t('settings.tab_charts_of_accounts.toast.coa_delete_fail'),
              {
                title: this.$t(this.translationPath + 'title'),
                variant: 'danger',
                solid: true,
                'auto-hide-delay': 3000
              }
            )
          }
        })
        .then(() => {
          process.nextTick(() => {
            this.coaToDelete = null
          })
        })
    },
    onCoaResetClick (item) {
      this.coaToReset = item
      this.$refs.ResetCoaModal.show()
    },
    onCoaResetCancelled () {
      this.coaToReset = null
    },
    onCoaResetConfirmed (eventData) {
      const deleteData = { reset_accounts: eventData.resetAccounts }

      axios.delete(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/truncate/${this.coaToReset.id}`, { data: deleteData })
        .then(response => response.data.data)
        .then(responseData => {
          this.loadCurrentCOA()
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.coa_reset_success', { coa_title: this.coaToReset.title }), {
            title: this.$t(this.translationPath + 'toast.coa_reset_title'),
            autoHideDelay: 3000,
            variant: 'success'
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.coa_reset_fail', { coa_title: this.coaToReset.title }), {
            title: this.$t(this.translationPath + 'toast.coa_reset_title'),
            autoHideDelay: 3000,
            variant: 'danger'
          })
        })
        .finally(() => {
          this.coaToReset = null
          this.$emit('reload')
        })
    },
    onSaved () {
      this.isFormEditShown = false
      this.loadCoas()
      this.$bvToast.toast(
        this.$t('settings.tab_chart_of_accounts.details_updated'),
        {
          title: this.$t(this.translationPath + 'title'),
          variant: 'success',
          solid: true,
          'auto-hide-delay': 3000
        }
      )
    }
  }
}
</script>

<style lang="scss">
.settings-coa-coas {
  table {
    .action-button {
      padding: 0;
      border: none;
      line-height: normal;
      i {
        margin: 0;
        font-size: 13px;
      }
      &:hover {
        background: transparent;
      }
      &:active {
        background-color: transparent !important;
        border-color: transparent !important;
      }
      &:focus {
        background: transparent;
        box-shadow: none !important;
      }
    }
  }
}
</style>
