<template>
  <div class="component-settings-kpi px-4">
    <h3 class="pt-4">{{ $t(translationPath + 'title') }}</h3>
    <div v-html="$t(translationPath + 'description')"></div>

    <template v-if="!isLoaded">
      <loader />
    </template>
    <template v-else>
      <b-form-group
        label-class="align-self-top pr-md-0 pr-lg-4 text-black"
        label-for="selected-kpi"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        class="pt-md-4"
        :label="$t(translationPath + 'fields.selected_kpi_label')"
        :description="$t(translationPath + 'fields.selected_kpi_description')"
      >
        <b-form-select
          v-model="selectedKpi"
          :options="kpiSelectOptions"
          @change="onKpiSelected"
          class="rounded-pill"
        />
      </b-form-group>

      <template v-if="selectedKpi && kpiSettings">
        <b-form-group
          label-class="align-self-top pr-md-0 pr-lg-4 text-black"
          label-cols-lg="3"
          label-cols-xl="3"
          label-align-lg="right"
          class="pt-md-4"
          :label="$t(translationPath + 'fields.preview_label')"
        >
          <dashboard-gauge-chart
            ref="PreviewChart"
            :id="selectedKpi.kpi_type"
            :options="JSON.parse(selectedKpi.json_settings)"
            :value="parseInt(selectedKpi.kpi_value)"
            :title="selectedKpi.kpi_type"
            :width="400"
            :needle-speed="0"
          />
        </b-form-group>

        <h3 class="mt-0">{{ $t(translationPath + 'section_start') }}</h3>

        <b-form-group
          label-class="align-self-top pr-md-0 pr-lg-4 text-black"
          label-for="start-label"
          label-cols-lg="3"
          label-cols-xl="3"
          label-align-lg="right"
          class="pt-md-4"
          :label="$t(translationPath + 'fields.start_label')"
        >
          <b-form-input
            id="start-label"
            class="rounded-pill"
            v-model="kpiSettings.range.start.label"
            @input="deploySettingsToKpi"
          />
        </b-form-group>
        <b-form-group
          label-class="align-self-top pr-md-0 pr-lg-4 text-black"
          label-for="start-value"
          label-cols-lg="3"
          label-cols-xl="3"
          label-align-lg="right"
          class="pt-md-4"
          :label="$t(translationPath + 'fields.start_value_label')"
        >
          <number-input
            id="start-value"
            :input-class="'rounded-pill ' + kpiSettingsFieldClasses.start"
            v-model="kpiSettings.range.start.value"
            :min="-9999999999"
            @input="deploySettingsToKpi"
          />
          <div class="invalid-feedback" v-if="kpiSettingsFieldClasses.start">{{ invalidValueSequenceMsg }}</div>
        </b-form-group>
        <b-form-group
          label-class="align-self-top pr-md-0 pr-lg-4 text-black"
          label-for="start-color"
          label-cols-lg="3"
          label-cols-xl="3"
          label-align-lg="right"
          class="pt-md-4"
          :label="$t(translationPath + 'fields.start_color_label')"
        >
          <b-form-input
            id="start-color"
            class="rounded-pill"
            v-model="kpiSettings.range.start.color"
            @input="deploySettingsToKpi"
          />
        </b-form-group>

        <h3 class="mt-5">{{ $t(translationPath + 'section_end') }}</h3>
        <b-form-group
          label-class="align-self-top pr-md-0 pr-lg-4 text-black"
          label-for="before-end-label"
          label-cols-lg="3"
          label-cols-xl="3"
          label-align-lg="right"
          class="pt-md-4"
          :label="$t(translationPath + 'fields.before_end_label')"
        >
          <b-form-input
            id="before-end-label"
            class="rounded-pill"
            v-model="kpiSettings.range.before_end.label"
            @input="deploySettingsToKpi"
          />
        </b-form-group>
        <b-form-group
          label-class="align-self-top pr-md-0 pr-lg-4 text-black"
          label-for="before-end-value"
          label-cols-lg="3"
          label-cols-xl="3"
          label-align-lg="right"
          class="pt-md-4"
          :label="$t(translationPath + 'fields.before_end_value_label')"
        >
          <number-input
            id="before-end-value"
            :input-class="'rounded-pill ' + kpiSettingsFieldClasses.before_end"
            v-model="kpiSettings.range.before_end.value"
            :min="-9999999999"
            @input="deploySettingsToKpi"
          />
          <div class="invalid-feedback" v-if="kpiSettingsFieldClasses.before_end">{{ invalidValueSequenceMsg }}</div>
        </b-form-group>
        <b-form-group
          label-class="align-self-top pr-md-0 pr-lg-4 text-black"
          label-for="before-end-color"
          label-cols-lg="3"
          label-cols-xl="3"
          label-align-lg="right"
          class="pt-md-4"
          :label="$t(translationPath + 'fields.before_end_color_label')"
        >
          <b-form-input
            id="before-end-color"
            class="rounded-pill"
            v-model="kpiSettings.range.before_end.color"
            @input="deploySettingsToKpi"
          />
        </b-form-group>
        <b-form-group
          label-class="align-self-top pr-md-0 pr-lg-4 text-black"
          label-for="end-label"
          label-cols-lg="3"
          label-cols-xl="3"
          label-align-lg="right"
          class="pt-md-4"
          :label="$t(translationPath + 'fields.end_label')"
        >
          <b-form-input
            id="end-label"
            class="rounded-pill"
            v-model="kpiSettings.range.end.label"
            @input="deploySettingsToKpi"
          />
        </b-form-group>
        <b-form-group
          label-class="align-self-top pr-md-0 pr-lg-4 text-black"
          label-for="end-value"
          label-cols-lg="3"
          label-cols-xl="3"
          label-align-lg="right"
          class="pt-md-4"
          :label="$t(translationPath + 'fields.end_value_label')"
        >
          <number-input
            id="end-label"
            :input-class="'rounded-pill ' + kpiSettingsFieldClasses.end"
            v-model="kpiSettings.range.end.value"
            :min="-9999999999"
            @input="deploySettingsToKpi"
          />
          <div class="invalid-feedback" v-if="kpiSettingsFieldClasses.end">{{ invalidValueSequenceMsg }}</div>
        </b-form-group>

        <h3 class="mt-5">{{ $t(translationPath + 'section_intermediate') }}</h3>
        <b-row no-gutters v-if="!intermediateRangeIndexes.length">
          <b-col>
            <b-link
              class="btn btn-xs btn-outline-primary ml-1 mb-2"
              href="#"
              @click.prevent="addFirstSection()"
              :title="$t(translationPath + 'buttons.add_section_first')"
            >
              <i class="flaticon stroke plus" />
               {{ $t(translationPath + 'buttons.add_section_first') }}
            </b-link>
          </b-col>
        </b-row>

        <div v-for="index in intermediateRangeIndexes" :key="index">
          <b-row no-gutters class="mt-4">
            <b-col cols="12" md="6" xl="7">
              <h6 class="mt-1">
                {{ $t(translationPath + 'section_intermediate_n', { title: kpiSettings.range[index].label }) }}
              </h6>
            </b-col>
            <b-col cols="12" md="6" xl="5" class="text-right">
              <b-link
                v-if="index > 0"
                class="btn btn-xs btn-outline-primary ml-1 mb-2"
                href="#"
                @click.prevent="swapSections(index, index - 1)"
                :title="$t(translationPath + 'buttons.move_section_prev')"
              >
                <i class="flaticon stroke arrow-up" />
              </b-link>
              <b-link
                v-if="index < intermediateRangeIndexes.length - 1"
                class="btn btn-xs btn-outline-primary ml-1 mb-2"
                href="#"
                @click.prevent="swapSections(index, index + 1)"
                :title="$t(translationPath + 'buttons.move_section_next')"
              >
                <i class="flaticon stroke arrow-down" />
              </b-link>
              <b-link
                class="btn btn-xs btn-outline-primary ml-1 mb-2"
                href="#"
                @click.prevent="addSectionBefore(index)"
                :title="$t(translationPath + 'buttons.add_section_before')"
              >
                <i class="flaticon stroke plus" />
                <i class="flaticon stroke arrow-up" />
              </b-link>
              <b-link
                class="btn btn-xs btn-outline-primary ml-1 mb-2"
                href="#"
                @click.prevent="addSectionAfter(index)"
                :title="$t(translationPath + 'buttons.add_section_after')"
              >
                <i class="flaticon stroke plus" />
                <i class="flaticon stroke arrow-down" />
              </b-link>
              <b-link
                class="btn btn-xs btn-outline-secondary ml-1 mb-2"
                href="#"
                @click.prevent="removeSection(index)"
                :title="$t(translationPath + 'buttons.remove_section')"
              >
                <i class="flaticon stroke trash-3" />
              </b-link>
            </b-col>
          </b-row>
          <b-form-group
            label-class="align-self-top pr-md-0 pr-lg-4 text-black"
            :label-for="'section-intermediate-label-' + index"
            label-cols-lg="3"
            label-cols-xl="3"
            label-align-lg="right"
            class="pt-md-4"
            :label="$t(translationPath + 'fields.section_intermediate_label')"
          >
            <b-form-input
              :id="'section-intermediate-label-' + index"
              class="rounded-pill"
              v-model="kpiSettings.range[index].label"
              @input="deploySettingsToKpi"
            />
          </b-form-group>
          <b-form-group
            label-class="align-self-top pr-md-0 pr-lg-4 text-black"
            :label-for="'section-intermediate-value-' + index"
            label-cols-lg="3"
            label-cols-xl="3"
            label-align-lg="right"
            class="pt-md-4"
            :label="$t(translationPath + 'fields.section_intermediate_value_label')"
          >
            <number-input
              :id="'section-intermediate-value-' + index"
              :input-class="'rounded-pill ' + kpiSettingsFieldClasses[index]"
              v-model="kpiSettings.range[index].value"
              :min="-9999999999"
              @input="deploySettingsToKpi"
            />
            <div class="invalid-feedback" v-if="kpiSettingsFieldClasses[index]">{{ invalidValueSequenceMsg }}</div>
          </b-form-group>
          <b-form-group
            label-class="align-self-top pr-md-0 pr-lg-4 text-black"
            :label-for="'section-intermediate-color-' + index"
            label-cols-lg="3"
            label-cols-xl="3"
            label-align-lg="right"
            class="pt-md-4"
            :label="$t(translationPath + 'fields.section_intermediate_color_label')"
          >
            <b-form-input
              :id="'section-intermediate-color-' + index"
              class="rounded-pill"
              v-model="kpiSettings.range[index].color"
              @input="deploySettingsToKpi"
            />
          </b-form-group>
        </div>

        <b-row no-gutters class="mt-5">
          <b-col class="text-right">
            <b-button variant="primary" @click="save()">{{ $t('common.save') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import DashboardGaugeChart from '@/components/charts/DashboardGaugeChart'
import NumberInput from '@/components/common/NumberInput'
import _ from 'lodash'

export default {
  name: 'Kpi',
  components: { Loader, DashboardGaugeChart, NumberInput },
  data () {
    return {
      translationPath: 'settings.tab_kpi.',
      isLoaded: false,
      kpi: [],
      selectedKpi: null,
      kpiSettings: null,
      kpiSettingsFieldClasses: { start: '', before_end: '', end: '' }
    }
  },
  computed: {
    kpiSelectOptions () {
      const opts = [{ text: '', value: null }]
      this.kpi.map(kpi => {
        opts.push({ text: kpi.kpi_type, value: kpi })
      })
      return opts
    },
    intermediateRangeIndexes () {
      if (!this.kpiSettings) {
        return []
      }

      const indexes = []
      for (const i in this.kpiSettings.range) {
        if (isNaN(Number(i))) {
          continue
        }

        indexes.push(Number(i))
      }
      indexes.sort()

      return indexes
    },
    invalidValueSequenceMsg () {
      return this.$t(this.translationPath + 'errors.invalid_value_sequence')
    }
  },
  mounted () {
    this.loadKpi()
  },
  methods: {
    async loadKpi () {
      return new Promise((resolve, reject) => {
        this.isLoaded = false
        axios.get(`${process.env.VUE_APP_ROOT_API}/kpi`)
          .then(response => {
            this.kpi = response.data.data
            resolve(response)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
          .finally(() => {
            this.isLoaded = true
          })
      })
    },
    parseKpiSettings () {
      this.kpiSettings = this.selectedKpi ? JSON.parse(this.selectedKpi.json_settings) : null
    },
    deploySettingsToKpi () {
      if (this.validateSettings()) {
        this.selectedKpi.json_settings = JSON.stringify(this.kpiSettings)
      }
    },
    onKpiSelected () {
      this.parseKpiSettings()
    },
    removeSection (index) {
      delete this.kpiSettings.range[index]
      this.kpiSettings.rangeCount--
      this.kpiSettings = _.cloneDeep(this.kpiSettings)
      this.deploySettingsToKpi()
    },
    addFirstSection () {
      const value = Math.round((this.kpiSettings.range.before_end.value - this.kpiSettings.range.start.value) / 2)

      this.kpiSettings.range[0] = {
        label: this.$t(this.translationPath + 'new_section_title'),
        color: this.getRandomColor(),
        value: value
      }

      this.kpiSettings = _.cloneDeep(this.kpiSettings)
      this.deploySettingsToKpi()
    },
    addSectionBefore (index) {
      const prevSectionIndex = index !== 0 ? index - 1 : 'start'
      const prevSectionValue = Number(this.kpiSettings.range[prevSectionIndex].value)
      const currentSectionValue = Number(this.kpiSettings.range[index].value)

      const section = {
        label: this.$t(this.translationPath + 'new_section_title'),
        color: this.getRandomColor(),
        value: Math.round((currentSectionValue - prevSectionValue) / 2 + prevSectionValue)
      }

      this.intermediateRangeIndexes.reverse().filter(i => i >= index).map(i => {
        this.kpiSettings.range[i + 1] = _.cloneDeep(this.kpiSettings.range[i])
      })
      this.kpiSettings.range[index] = section

      this.kpiSettings = _.cloneDeep(this.kpiSettings)
      this.deploySettingsToKpi()
    },
    addSectionAfter (index) {
      const nextSectionIndex = index < this.intermediateRangeIndexes.length - 1 ? index + 1 : 'before_end'
      const nextSectionValue = Number(this.kpiSettings.range[nextSectionIndex].value)
      const currentSectionValue = Number(this.kpiSettings.range[index].value)

      const section = {
        label: this.$t(this.translationPath + 'new_section_title'),
        color: this.getRandomColor(),
        value: Math.round((nextSectionValue - currentSectionValue) / 2 + currentSectionValue)
      }

      this.intermediateRangeIndexes.reverse().filter(i => i > index).map(i => {
        this.kpiSettings.range[i + 1] = _.cloneDeep(this.kpiSettings.range[i])
      })
      this.kpiSettings.range[index + 1] = section

      this.kpiSettings = _.cloneDeep(this.kpiSettings)
      this.deploySettingsToKpi()
    },
    swapSections (indexA, indexB) {
      const a = _.cloneDeep(this.kpiSettings.range[indexA])
      const b = _.cloneDeep(this.kpiSettings.range[indexB])
      a.value = this.kpiSettings.range[indexB].value
      b.value = this.kpiSettings.range[indexA].value

      this.kpiSettings.range[indexA] = b
      this.kpiSettings.range[indexB] = a

      this.kpiSettings = _.cloneDeep(this.kpiSettings)
      this.deploySettingsToKpi()
    },
    validateSettings () {
      let isValid = true

      const indexSequence = ['start', ...this.intermediateRangeIndexes, 'before_end', 'end']
      for (const i in indexSequence) {
        if (indexSequence[i] !== 'end') {
          const currentValue = Number(this.kpiSettings.range[indexSequence[i]].value)
          const nextValue = Number(this.kpiSettings.range[indexSequence[Number(i) + 1]].value)

          if (currentValue >= nextValue) {
            isValid = false
            break
          }
        }

        if (isNaN(Number(this.kpiSettings.range[indexSequence[i]].value))) {
          isValid = false
          break
        }
      }

      this.calculateKpiSettingsFieldClasses()
      return isValid
    },
    calculateKpiSettingsFieldClasses () {
      const indexSequence = ['start', ...this.intermediateRangeIndexes, 'before_end', 'end']
      indexSequence.map(index => {
        this.kpiSettingsFieldClasses[index] = ''
      })

      for (const i in indexSequence) {
        if (['start', 'end'].indexOf(indexSequence[i]) !== -1) {
          continue
        }

        const currentValue = Number(this.kpiSettings.range[indexSequence[i]].value)
        const prevValue = Number(this.kpiSettings.range[indexSequence[parseInt(i) - 1]].value)
        const nextValue = Number(this.kpiSettings.range[indexSequence[parseInt(i) + 1]].value)

        if (currentValue <= prevValue) {
          this.kpiSettingsFieldClasses[indexSequence[i]] = 'is-invalid'
          this.kpiSettingsFieldClasses[indexSequence[parseInt(i) - 1]] = 'is-invalid'
        } else if (currentValue >= nextValue) {
          this.kpiSettingsFieldClasses[indexSequence[i]] = 'is-invalid'
          this.kpiSettingsFieldClasses[indexSequence[parseInt(i) + 1]] = 'is-invalid'
        }
      }
    },
    save () {
      if (!this.validateSettings()) {
        return false
      }

      const data = { json_settings: this.selectedKpi.json_settings }
      axios.put(`${process.env.VUE_APP_ROOT_API}/kpi/${this.selectedKpi.id}`, data)
        .then(response => {
          this.$bvToast.toast(
            this.$t(this.translationPath + 'toast.save_success'),
            {
              title: this.$t(this.translationPath + 'toast.title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(
            this.$t(this.translationPath + 'toast.save_fail'),
            {
              title: this.$t(this.translationPath + 'toast.title'),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
        })
    },
    getRandomColor () {
      return '#' + Math.floor(Math.random() * 16777215).toString(16)
    }
  }
}
</script>

<style lang="scss">
.component-settings-kpi {
  .invalid-feedback {
    display: block;
  }
  .form-row {
    padding-top: 0px !important;
  }
  .btn-xs {
    font-size: 12px;
  }
}
</style>
