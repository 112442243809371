<template>
  <div class="component-settings-coa-finance-settings px-4">
    <b-form @submit.prevent="updateDetails">

      <h3 class="pt-4">{{$t(translationPath + 'standard_settings')}}</h3>
      <div v-html="$t(translationPath + 'standard_settings_description')"></div>

      <!-- NUMBER OF ROWS -->
      <b-form-group
        label-class="align-self-top pr-md-0 pr-lg-4 text-black"
        label-for="default_pagination_rows"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        class="pt-md-4"
        :label="$t(translationPath + 'pagination')"
        :description="$t(translationPath + 'pagination_description')"
      >
        <b-form-select
          v-model="default_pagination_rows"
          :options="paginationOptions"
          class="rounded-pill"
        />
      </b-form-group>

      <!-- account codes -->
      <b-form-group
        label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
        label-for="prefix_account_title_with_code"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        class="pt-md-4"
        :label="$t(translationPath + 'prefix_with_code')"
        :description="$t(translationPath + 'prefix_with_code_description')"
      >
        <b-form-select
          v-model="prefix_account_title_with_code"
          :options="prefixOptions"
          class="rounded-pill"
        />
      </b-form-group>

      <!-- locale -->
      <b-form-group
        label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
        label-for="locale"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        class="pt-md-4"
        :label="$t(translationPath + 'locale')"
        :description="$t(translationPath + 'locale_description')"
      >
        <b-form-select
          v-model="locale"
          :options="localOptions"
          class="rounded-pill"
        />
      </b-form-group>

      <!-- currency -->
      <b-form-group
        label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
        label-for="currency"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        class="pt-md-4"
        :label="$t(translationPath + 'currency')"
      >
        <b-form-select v-model="currency" class="rounded-pill">
          <option :value="null">
            {{ $t(translationPath + "select_currency_placeholder") }}
          </option>
          <option value="kr">kr</option>
          <option value="€">&euro;</option>
          <option value="$">$</option>
          <option value="£">£</option>
        </b-form-select>
      </b-form-group>

      <!-- currency_iso -->
      <b-form-group
        label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
        label-for="currency_iso"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        class="pt-md-4"
        :label="$t(translationPath + 'currency_iso')"
        :description="$t(translationPath + 'currency_iso_description')"
      >
        <b-form-select
          v-model="currency_iso"
          :options="currencyISOOptions"
          class="rounded-pill"
        />
      </b-form-group>

      <template v-if="!actionCreate">
        <hr class="mt-5" />

        <h3 class="pt-4">{{$t(translationPath + 'observation_account_settings')}}</h3>
        <div class="text-regular" v-html="$t(translationPath + 'observation_account_settings_description')"></div>

        <!-- INCOME OBSERVATION ACCOUNT -->
        <b-form-group
          label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
          label-for="income_observation_account_id"
          label-cols-lg="3"
          label-cols-xl="3"
          label-align-lg="right"
          class="pt-md-4"
          :label="$t(translationPath + 'income_observation')"
        >
          <template v-slot:description>
            {{ $t(translationPath + "income_observation_description") }}
          </template>
          <custom-select
            v-model="income_observation_account_id"
            :options="categoryOptions.income"
            groupingValues="accounts"
            groupingLabel="title"
            :placeholder="''"
            :search-placeholder="$t('common.type_to_filter').toString()"
            :noResultText="$t('common.no_option_found').toString()"
            :sortGroupLabel="false"
            @open="handleCategoryOpen('income')"
            label="title"
          />
        </b-form-group>

        <!-- EXPENSE OBSERVATION ACCOUNT -->
        <b-form-group
          label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
          label-for="expense_observation_account_id"
          label-cols-lg="3"
          label-cols-xl="3"
          label-align-lg="right"
          class="pt-md-4"
          :label="$t(translationPath + 'expense_observation')"
        >
          <template v-slot:description>
            {{ $t(translationPath + "expense_observation_description") }}
          </template>
          <custom-select
            v-model="expense_observation_account_id"
            :options="categoryOptions.expense"
            groupingValues="accounts"
            groupingLabel="title"
            :placeholder="''"
            :search-placeholder="$t('common.type_to_filter').toString()"
            :noResultText="$t('common.no_option_found').toString()"
            :sortGroupLabel="false"
            @open="handleCategoryOpen('expense')"
            label="title"
          />
        </b-form-group>

        <!-- TRANSFER OBSERVATION ACCOUNT -->
        <b-form-group
          label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
          label-for="transfer_observation_account_id"
          label-cols-lg="3"
          label-cols-xl="3"
          label-align-lg="right"
          class="pt-md-4"
          :label="$t(translationPath + 'transfer_observation')"
        >
          <template v-slot:description>
            {{ $t(translationPath + "transfer_observation_description") }}
          </template>
          <custom-select
            v-model="transfer_observation_account_id"
            :options="categoryOptions.assetliability"
            groupingValues="accounts"
            groupingLabel="title"
            :placeholder="''"
            :search-placeholder="$t('common.type_to_filter').toString()"
            :noResultText="$t('common.no_option_found').toString()"
            :sortGroupLabel="false"
            @open="handleCategoryOpen('assetliability')"
            label="title"
          />
        </b-form-group>
      </template>

      <hr class="mt-5" />

      <h3 class="pt-4">{{$t(translationPath + 'advanced_settings')}}</h3>
      <div class="text-regular" v-html="$t(translationPath + 'advanced_settings_description')"></div>
      <b-form-group
        v-if="!actionCreate"
        label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
        label-for="networth_account_id"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        class="pt-md-4"
        :label="$t(translationPath + 'networth_account')"
      >
        <template v-slot:description>
          {{ $t(translationPath + "networth_account_description") }}
          <b-link
            target="_blank"
            href="https://forum.spirecta.se/t/systemkategorin-eget-kapital/60"
            class="text-red"
            >{{ $t("common.read_more_text") }}.</b-link
          >
        </template>
        <custom-select
          v-model="networth_account_id"
          :options="categoryOptions.liability"
          groupingValues="accounts"
          groupingLabel="title"
          :placeholder="''"
          :search-placeholder="$t('common.type_to_filter').toString()"
          :noResultText="$t('common.no_option_found').toString()"
          :sortGroupLabel="false"
          @open="handleCategoryOpen('liability')"
          label="title"
        />
      </b-form-group>

      <!-- COA TITLE -->
      <b-form-group
        label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
        label-for="title"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        class="pt-md-4"
        :label="$t(translationPath + 'chart_of_accounts')"
        :description="$t(translationPath + 'chart_of_accounts_description')"
        :invalid-feedback="invalidTitleFeedback"
      >
        <b-form-input v-model="title" class="rounded-pill" :state="$v.title.$dirty ? !$v.title.$error : null" />
      </b-form-group>

      <!-- SIMULATION END DATE -->
      <b-form-group
        label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
        label-for="title"
        label-cols-lg="3"
        label-cols-xl="3"
        label-align-lg="right"
        class="pt-md-4"
        :label="$t(translationPath + 'simulation_end_date')"
        :description="$t(translationPath + 'simulation_end_date_description')"
        :invalid-feedback="invalidSimulationEndDateFeedback"
      >
        <b-form-input v-model="simulationEndDate" class="rounded-pill" :state="$v.simulationEndDate.$dirty ? !$v.simulationEndDate.$error : null" />
      </b-form-group>

      <b-row>
        <b-col class="pt-4 pt-md-1">
          <b-button type="submit" variant="primary" class="float-right btn-save">
            {{ $t("common.save") }}
          </b-button>
          <b-button v-if="showCancelButton" variant="outline-secondary" class="float-left btn-save" @click="$emit('canceled')">
            {{ $t("common.cancel") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { SET_SETTINGS_PAGINATION_ROWS_PER_PAGE } from '@/store/actions/settings'
import CustomSelect from '@/components/common/CustomSelect'
import axios from 'axios'
import { mapState, mapActions } from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import _ from 'lodash'
import moment from 'moment'

const isDateValid = getter => function (value) {
  if (!/\d{4}-\d{2}/.test(value)) {
    return false
  }
  const month = Number(value.substr(5))
  if (month > 12 || month === 0) {
    return false
  }
  return true
}

export default {
  name: 'FinanceSettings',
  components: {
    CustomSelect
  },
  props: {
    coaId: {
      type: Number,
      default: 0 // If 0 - currentCOA will be used
    },
    actionCreate: {
      type: Boolean,
      default: false // If true - new COA will be created. If false - updated where id === coaId
    },
    showCancelButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      translationPath: 'settings.tab_chart_of_accounts.',
      title: null,
      currency: null,
      currency_iso: null,
      locale: null,
      simulationEndDate: moment().endOf('year').add('40', 'years').format('YYYY-MM'),
      income_observation_account_id: null,
      expense_observation_account_id: null,
      transfer_observation_account_id: null,
      prefix_account_title_with_code: null,
      default_pagination_rows: null,
      networth_account_id: null,
      networthOptions: [],
      incomeOptions: [],
      expenseOptions: [],
      transferOptions: [],
      categoryOptions: {
        income: null,
        expense: null,
        assetliability: null
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    currencyISOOptions () {
      return [
        { text: 'DKK', value: 'DKK' },
        { text: 'EUR', value: 'EUR' },
        { text: 'NOK', value: 'NOK' },
        { text: 'SEK', value: 'SEK' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' }
      ]
    },
    paginationOptions () {
      return [
        { text: '5', value: '5' },
        { text: '10', value: '10' },
        { text: '25', value: '25' },
        { text: '50', value: '50' },
        { text: '100', value: '100' },
        { text: '150', value: '150' }
      ]
    },
    localOptions () {
      return [
        {
          value: 'sv-SE',
          text: this.$t(this.translationPath + 'locale_sv-SE')
        },
        {
          value: 'da-DK',
          text: this.$t(this.translationPath + 'locale_da-DK')
        },
        {
          value: 'en-US',
          text: this.$t(this.translationPath + 'locale_en-US')
        }
      ]
    },
    prefixOptions () {
      return [
        { text: this.$t('common.yes'), value: 1 },
        { text: this.$t('common.no'), value: 0 }
      ]
    },
    transferOptionExc () {
      return this.transferOptions
        ? [...this.transferOptions].filter((el) => el.value !== this.networth_account_id) : this.transferOptions
    },
    invalidTitleFeedback () {
      if (this.$v.title.required === false) return this.$t(this.translationPath + 'errors.title.required')
      if (this.$v.title.minLength === false) return this.$t(this.translationPath + 'errors.title.min_length')
      if (this.$v.title.maxLength === false) return this.$t(this.translationPath + 'errors.title.max_length')
      return ''
    },
    invalidSimulationEndDateFeedback () {
      if (this.$v.simulationEndDate.required === false) return this.$t(this.translationPath + 'errors.simulation_end_date.required')
      if (this.$v.simulationEndDate.isDateValid === false) return this.$t(this.translationPath + 'errors.simulation_end_date.invalid_format')
      return ''
    }
  },
  validations: {
    title: { required, minLength: minLength(2), maxLength: maxLength(255) },
    simulationEndDate: { required, isDateValid: isDateValid() },
    form: ['title', 'simulationEndDate']
  },
  async created () {
    const categoryOpt = await this.loadAccounts()
    this.categoryOptions = categoryOpt
    this.currentUserDetails()
  },
  methods: {
    ...mapActions('user', ['loadCurrentCOA']),
    handleCategoryOpen (type) {
      if (!Object.prototype.hasOwnProperty.call(this.categoryOptions, type)) {
        this.loadOptions()
      }
    },
    getValue (obj, propertyName, defaultValue = null) {
      return obj &&
        Object.prototype.hasOwnProperty.call(
          obj,
          propertyName
        )
        ? obj[propertyName]
        : defaultValue
    },
    loadAccounts () {
      let sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?log=createMultipleTransactions&include_networth_account=1`
      if (this.coaId) {
        sApiEndpoint += `&coa_id=${this.coaId}`
      }
      return axios.get(sApiEndpoint)
        .then(response => response.data.data)
        .then(responseData => {
          const self = this

          const optionsData = responseData.map(items => {
            for (const index in items.accounts) {
              items.accounts[index].title = self.currentCOA.prefix_account_title_with_code ? items.accounts[index].title_with_code : items.accounts[index].title
            }

            return Object.assign(items, {
              // title: this.$te('common' + items.type.toLowerCase()) ? this.$t('common' + items.type.toLowerCase()) + ' / ' + items.title : items.type + ' / ' + items.title,
              title: this.$t('common.' + items.type.toLowerCase()) + ' / ' + self.currentCOA.prefix_account_title_with_code ? items.title_with_code : items.title,
              accounts: items.accounts,
              rawTitle: items.title
            })
          })

          return {
            income: optionsData.filter(item => item.type === 'income'),
            expense: optionsData.filter(item => item.type === 'expense'),
            liability: optionsData.filter(item => item.type === 'liability'),
            assetliability: optionsData.filter(item => ['asset', 'liability'].indexOf(item.type) > -1)
          }
        })
        .catch(err => {
          console.error(err)
          return {
            options: [],
            loading: false
          }
        })
    },
    getSelectedAccount (options, value) {
      if (!options) {
        return null
      }
      const selectedAccount = _.flattenDeep(options.map((el) => el.accounts)).filter((el) => el.id === Number(value))
      return selectedAccount.length > 0 ? selectedAccount[0] : null
    },
    currentUserDetails () {
      if (!this.coaId && !this.actionCreate) {
        this.title = this.getValue(this.currentCOA, 'title')
        this.currency = this.getValue(this.currentCOA, 'currency')
        this.currency_iso = this.getValue(this.currentCOA, 'currency_iso')
        this.locale = this.getValue(this.currentCOA, 'locale')
        this.prefix_account_title_with_code = this.getValue(this.currentCOA, 'prefix_account_title_with_code')
        this.networth_account_id = this.getValue(this.currentCOA, 'networth_account_id')
        this.default_pagination_rows = this.getValue(this.currentCOA, 'default_pagination_rows', 50)
        this.simulationEndDate = this.getValue(this.currentCOA, 'default_simulation_end_date', moment().endOf('year').add(40, 'years').format('YYYY-MM'))

        const incomeObservationValue = this.getValue(this.currentCOA, 'income_observation_account_id')
        if (incomeObservationValue) {
          this.income_observation_account_id = this.getSelectedAccount(this.categoryOptions.income, incomeObservationValue)
        }

        const expenseObservationValue = this.getValue(this.currentCOA, 'expense_observation_account_id')
        if (expenseObservationValue) {
          this.expense_observation_account_id = this.getSelectedAccount(this.categoryOptions.expense, expenseObservationValue)
        }

        const transferObservationValue = this.getValue(this.currentCOA, 'transfer_observation_account_id')
        if (transferObservationValue) {
          this.transfer_observation_account_id = this.getSelectedAccount(this.categoryOptions.assetliability, transferObservationValue)
        }

        if (this.networth_account_id) {
          this.networth_account_id = this.getSelectedAccount(this.categoryOptions.liability, this.networth_account_id)
        }
      } else if (this.coaId && !this.actionCreate) {
        axios.get(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/${this.coaId}`)
          .then(response => {
            this.title = response.data.data.title
            this.currency = response.data.data.currency
            this.currency_iso = response.data.data.currency_iso
            this.locale = response.data.data.locale
            this.prefix_account_title_with_code = response.data.data.prefix_account_title_with_code
            this.default_pagination_rows = response.data.data.default_pagination_rows
            this.income_observation_account_id = this.getSelectedAccount(this.categoryOptions.income, response.data.data.income_observation_account_id)
            this.expense_observation_account_id = this.getSelectedAccount(this.categoryOptions.expense, response.data.data.expense_observation_account_id)
            this.transfer_observation_account_id = this.getSelectedAccount(this.categoryOptions.assetliability, response.data.data.transfer_observation_account_id)
            this.networth_account_id = this.getSelectedAccount(this.categoryOptions.liability, response.data.data.networth_account_id)
          })
          .catch(err => {
            console.error(err)
          })
      } else {
        this.currency = this.getValue(this.currentCOA, 'currency')
        this.currency_iso = this.getValue(this.currentCOA, 'currency_iso')
        this.locale = this.getValue(this.currentCOA, 'locale')
        this.prefix_account_title_with_code = this.getValue(this.currentCOA, 'prefix_account_title_with_code')
        this.default_pagination_rows = this.getValue(this.currentCOA, 'default_pagination_rows', 50)
      }
    },
    async updateDetails () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }

      // eslint-disable-next-line
      const {
        title,
        currency,
        currency_iso,
        locale,
        prefix_account_title_with_code,
        networth_account_id,
        default_pagination_rows,
        income_observation_account_id,
        expense_observation_account_id,
        transfer_observation_account_id,
        simulationEndDate
      } = this
      // eslint-disable-next-line
      let userData = {
        title,
        currency,
        currency_iso,
        locale,
        prefix_account_title_with_code,
        default_pagination_rows,
        networth_account_id: networth_account_id ? networth_account_id.id.toString() : null,
        income_observation_account_id: income_observation_account_id ? income_observation_account_id.id.toString() : null,
        expense_observation_account_id: expense_observation_account_id ? expense_observation_account_id.id.toString() : null,
        transfer_observation_account_id: transfer_observation_account_id ? transfer_observation_account_id.id.toString() : null,
        default_simulation_end_date: simulationEndDate
      }

      let sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/charts-of-accounts`
      let sApiMethod = 'post'
      if (!this.actionCreate) {
        sApiMethod = 'put'
        sApiEndpoint += '/' + (this.coaId ? this.coaId : this.currentCOA.id)
      } else {
        userData.fill_with_defaults = true
      }

      await axios({
        method: sApiMethod,
        url: sApiEndpoint,
        data: userData
      })
        .then((response) => {
          this.loadCurrentCOA().then((data) => {
            // eslint-disable-next-line camelcase
            const { default_pagination_rows } = data
            // eslint-disable-next-line camelcase
            if (default_pagination_rows !== 0) {
              this.$store.dispatch(
                SET_SETTINGS_PAGINATION_ROWS_PER_PAGE,
                default_pagination_rows
              )
            }
          })
          this.$bvToast.toast(
            this.$t(this.translationPath + 'details_updated'),
            {
              title: this.$t(this.translationPath + 'title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
          this.$emit('saved')
        })
        .catch(function (error) {
          console.error(error)
        })
    }
  },
  watch: {
    currentCOA: {
      handler: function () {
        this.currentUserDetails()
      },
      deep: true
    },
    networth_account_id: {
      handler: function (value) {
        const netAccountId = value
        const transAccountId = this.getValue(this.transfer_observation_account_id, 'id')
        if (netAccountId && transAccountId && netAccountId === transAccountId) {
          this.transfer_observation_account_id = null
        }
      },
      deep: true
    },
    transfer_observation_account_id: {
      handler: function (value) {
        const netAccountId = this.networth_account_id
        const transAccountId = this.getValue(value, 'id')
        if (netAccountId && transAccountId && netAccountId === transAccountId) {
          this.transfer_observation_account_id = null
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/settings.scss";
</style>
