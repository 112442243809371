<template>
  <b-container class="settings-tab bg-light page-wrapper">
    <b-row class="main-content-wrapper pb-0" no-gutters>
      <b-col>
        <settings-submenu></settings-submenu>
      </b-col>
    </b-row>
    <b-row class="main-content-wrapper pt-0" no-gutters>
      <b-col sm="12" md="5" lg="3" class="detail-text text-left py-4 pr-5">
        <h3 class="text-dark">{{ $t(translationPath + 'title') }}</h3>
        <p class="text-gray">{{ $t(translationPath + 'description') }}</p>
      </b-col>
      <b-col sm="12" md="7" lg="9" class="py-2 px-0">
        <b-tabs v-if="activeTab !== -1" v-model="activeTab" card class="transparent-tabs">
          <b-tab :title="$t('settings.submenu.chart_of_accounts')" class="pt-3">
            <finance-settings/>
          </b-tab>
          <b-tab :title="$t('settings.submenu.household')" class="pt-3">
            <household-settings/>
          </b-tab>
          <b-tab :title="$t('settings.submenu.banks')" class="pt-3">
            <banks-settings/>
          </b-tab>
          <b-tab :title="$t('settings.submenu.budgets')" class="pt-3">
            <budgets />
          </b-tab>
          <b-tab :title="$t('settings.submenu.charts_of_accounts')" class="pt-3">
            <charts-of-accounts />
          </b-tab>
          <b-tab :title="'KPIs'" v-if="inDevelopmentMode">
            <kpi />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TitleMixins from '@/mixins/title'
import SettingsSubmenu from '../SettingsSubmenu'
import FinanceSettings from './FinanceSettings'
import HouseholdSettings from './HouseholdSettings'
import BanksSettings from './BanksSettings'
import Budgets from './Budgets'
import ChartsOfAccounts from './ChartsOfAccounts'
import Kpi from './Kpi'

export default {
  name: 'ChartAccount',
  components: { SettingsSubmenu, FinanceSettings, HouseholdSettings, BanksSettings, Budgets, ChartsOfAccounts, Kpi },
  mixins: [TitleMixins],
  data () {
    return {
      translationPath: 'settings.tab_chart_of_accounts.',
      activeTab: -1
    }
  },
  computed: {
    titleMeta () {
      return this.$t('settings.submenu.chart_of_accounts')
    },
    inDevelopmentMode () {
      return process.env.NODE_ENV === 'development'
    }
  },
  mounted () {
    this.onRouteChange(this.$route.params.tab)
  },
  methods: {
    onRouteChange (tab) {
      switch (tab) {
        case 'finances':
          this.activeTab = 0
          break
        case 'household':
          this.activeTab = 1
          break
        case 'banks':
          this.activeTab = 2
          break
        case 'budgets':
          this.activeTab = 3
          break
        case 'manage':
          this.activeTab = 4
          break
        case 'kpi':
          this.activeTab = 5
          break
        default:
          this.$router.push({ params: { tab: 'finances' } })
      }
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    activeTab (newVal) {
      switch (newVal) {
        case 0:
          if (this.$route.params.tab !== 'finances') {
            this.$router.push({ params: { tab: 'finances' } })
          }
          break
        case 1:
          if (this.$route.params.tab !== 'household') {
            this.$router.push({ params: { tab: 'household' } })
          }
          break
        case 2:
          if (this.$route.params.tab !== 'banks') {
            this.$router.push({ params: { tab: 'banks' } })
          }
          break
        case 3:
          if (this.$route.params.tab !== 'budgets') {
            this.$router.push({ params: { tab: 'budgets' } })
          }
          break
        case 4:
          if (this.$route.params.tab !== 'manage') {
            this.$router.push({ params: { tab: 'manage' } })
          }
          break
        case 5:
          if (this.$route.params.tab !== 'kpi') {
            this.$router.push({ params: { tab: 'kpi' } })
          }
      }
    },
    '$route.params.tab' (newVal) {
      this.onRouteChange(newVal)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/settings.scss';
.transparent-tabs .card-body {
  padding: 0;
}
</style>
