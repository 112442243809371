<template>
  <b-form @submit.prevent="updateBanksShowOptions">
    <p class="py-3 px-3 text-regular">{{$t(translationPath + "description")}}. <b-link class="color-red" href="https://forum.spirecta.se/t/anslutning-till-din-bank-eller-annan-leverantor-via-tink/896" target="_blank">{{$t('common.read_in_manual')}}</b-link></p>

    <!-- Locale based banks -->
    <b-form-group
      label-class="align-self-top pt-0 pr-md-0 pr-lg-4 text-black"
      label-for="locale_banks"
      label-cols-lg="3"
      label-cols-xl="3"
      label-align-lg="right"
      class="pt-md-4"
      :label="$t(translationPath + 'locale_banks_label')"
      :description="$t(translationPath + 'locale_banks_description')"
    >
      <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="localeBanks" />
    </b-form-group>

    <!-- Sweden banks -->
    <b-form-group
      label-class="align-self-top pt-0 pr-md-0 pr-lg-4 text-black"
      label-for="se_banks"
      label-cols-lg="3"
      label-cols-xl="3"
      label-align-lg="right"
      class="pt-md-4"
      :label="$t(translationPath + 'se_banks_label')"
      :description="$t(translationPath + 'se_banks_description')"
    >
      <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="seBanks" />
    </b-form-group>

    <!-- Denmark -->
    <b-form-group
      label-class="align-self-top pt-0 pr-md-0 pr-lg-4 text-black"
      label-for="dk_banks"
      label-cols-lg="3"
      label-cols-xl="3"
      label-align-lg="right"
      class="pt-md-4"
      :label="$t(translationPath + 'dk_banks_label')"
      :description="$t(translationPath + 'dk_banks_description')"
    >
      <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="dkBanks" />
    </b-form-group>

    <!-- Norway banks -->
    <b-form-group
      label-class="align-self-top pt-0 pr-md-0 pr-lg-4 text-black"
      label-for="no_banks"
      label-cols-lg="3"
      label-cols-xl="3"
      label-align-lg="right"
      class="pt-md-4"
      :label="$t(translationPath + 'no_banks_label')"
      :description="$t(translationPath + 'no_banks_description')"
    >
      <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="noBanks" />
    </b-form-group>

    <!-- Finland banks -->
    <b-form-group
      label-class="align-self-top pt-0 pr-md-0 pr-lg-4 text-black"
      label-for="fi_banks"
      label-cols-lg="3"
      label-cols-xl="3"
      label-align-lg="right"
      class="pt-md-4"
      :label="$t(translationPath + 'fi_banks_label')"
      :description="$t(translationPath + 'fi_banks_description')"
    >
      <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="fiBanks" />
    </b-form-group>

    <!-- Germany banks -->
    <b-form-group
      label-class="align-self-top pt-0 pr-md-0 pr-lg-4 text-black"
      label-for="de_banks"
      label-cols-lg="3"
      label-cols-xl="3"
      label-align-lg="right"
      class="pt-md-4"
      :label="$t(translationPath + 'de_banks_label')"
      :description="$t(translationPath + 'de_banks_description')"
    >
      <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="deBanks" />
    </b-form-group>

    <b-row>
      <b-col class="pt-4 pt-md-1">
        <b-button type="submit" variant="primary" class="float-right btn-save" :disabled="!isSubmitEnabled">
          {{ $t("common.save") }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import axios from 'axios'

export default {
  name: 'BanksSettings',
  data () {
    return {
      translationPath: 'settings.tab_chart_of_accounts.banks.',
      localeBanks: 0,
      seBanks: 0,
      dkBanks: 0,
      noBanks: 0,
      fiBanks: 0,
      deBanks: 0,
      isSubmitEnabled: false
    }
  },
  computed: {
    yesNoOptions () {
      return [
        { text: this.$t('common.yes'), value: 1 },
        { text: this.$t('common.no'), value: 0 }
      ]
    }
  },
  methods: {
    loadBanksShowOptions () {
      axios.get(`${process.env.VUE_APP_ROOT_API}/tink/banks-show-options`)
        .then(response => {
          this.localeBanks = response.data.data.locale_banks
          this.seBanks = response.data.data.se_banks
          this.dkBanks = response.data.data.dk_banks
          this.noBanks = response.data.data.no_banks
          this.fiBanks = response.data.data.fi_banks
          this.deBanks = response.data.data.de_banks
          this.isSubmitEnabled = true
        })
        .catch(err => {
          console.error(err)
        })
    },
    updateBanksShowOptions () {
      if (!this.localeBanks && !this.seBanks && !this.dkBanks) {
        this.$bvToast.toast(
          this.$t(this.translationPath + 'toast_missed_data'),
          {
            title: this.$t(this.translationPath + 'toast_title'),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          }
        )
        return false
      }

      this.isSubmitEnabled = false

      const putData = {
        locale_banks: this.localeBanks,
        se_banks: this.seBanks,
        dk_banks: this.dkBanks,
        no_banks: this.noBanks,
        fi_banks: this.fiBanks,
        de_banks: this.deBanks
      }

      axios.put(`${process.env.VUE_APP_ROOT_API}/tink/banks-show-options`, putData)
        .then(response => {
          this.$bvToast.toast(
            this.$t(this.translationPath + 'toast_updated'),
            {
              title: this.$t(this.translationPath + 'toast_title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(
            this.$t(this.translationPath + 'toast_not_updated'),
            {
              title: this.$t(this.translationPath + 'toast_title'),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
        })
        .then(() => {
          this.isSubmitEnabled = true
        })
    }
  },
  created () {
    this.loadBanksShowOptions()
  }
}
</script>
