<template>
  <div id="household-settings">
    <template v-if="show_add_form">
      <h3 :class="{ 'formTitle border-top-0 mt-0': true }">
        {{ $t(translationPath + "button." + (id ? "edit_person" : "add_person")) }}
      </h3>

      <form class="addPersonForm" @submit.prevent="save">
        <!--first name-->
        <b-form-group label-class="align-self-center pr-md-0 pr-lg-4 text-black" label-for="first_name" label-cols-lg="3" label-cols-xl="3" label-align-lg="right" class="pt-md-4" :label="$t(formLabel + 'first_name')" :invalid-feedback="invalidFirstFeedback">
          <b-form-input v-model.trim="$v.first_name.$model" id="first_name" :state="firstNameState" :placeholder="$t(translationPath + 'form.placeholders.firstname')" class="rounded-pill" />
        </b-form-group>

        <!--last name-->
        <b-form-group label-class="align-self-center pr-md-0 pr-lg-4 text-black" label-for="last_name" label-cols-lg="3" label-cols-xl="3" label-align-lg="right" class="pt-md-4" :label="$t(formLabel + 'last_name')" :invalid-feedback="invalidFirstFeedback">
          <b-form-input v-model="$v.last_name.$model" id="last_name" :state="lastNameState" :placeholder="$t(translationPath + 'form.placeholders.lastname')" class="rounded-pill" />
        </b-form-group>

        <!--dob-->
        <b-form-group label-class="align-self-center pr-md-0 pr-lg-4 text-black" label-for="dob" label-cols-lg="3" label-cols-xl="3" label-align-lg="right" class="pt-md-4" :label="$t(formLabel + 'dob')" :invalid-feedback="invalidDobFeedback" :state="dobState">
          <datepicker :input-class="{
              'form-control rounded-pill': true,
              is_valid: dobState === true,
              is_invalid: dobState === false
            }" :state="dobState" v-model="$v.dob.$model" :language="selectedLang" :placeholder="$t(translationPath + 'form.placeholders.dob')" :format="dateFormatter" typeable :disabled-dates="disabledDates" :monday-first="startWeekByMonday" @selected="$v.dob.$touch" :minimumView="'year'" :maximumView="'year'"></datepicker>
        </b-form-group>

        <!--sex-->
        <b-form-group label-class="align-self-center pr-md-0 pr-lg-4 text-black" label-for="sex" label-cols-lg="3" label-cols-xl="3" label-align-lg="right" class="pt-md-4" :label="$t(formLabel + 'sex')" :state="$v.sex.$dirty ? !$v.sex.$invalid : null" :invalid-feedback="
            $v.sex.$dirty && $v.sex.$invalid ? 'Sex is required.' : ''
          ">
          <b-form-radio-group id="sex" v-model="$v.sex.$model" :options="sexOptions" name="sex" :state="$v.sex.$dirty ? !$v.sex.$invalid : null"></b-form-radio-group>
        </b-form-group>

        <!--Yes No Options Field-->
        <template v-for="(item, key) in yesNoFields">
          <b-form-group :label-class="item.label_class" :label-for="item.label_for" label-cols-lg="3" label-cols-xl="3" label-align-lg="right" class="pt-md-4" :label="item.label" v-bind:key="key" :state="
              Object.prototype.hasOwnProperty.call(item, 'state')
                ? item.state
                : null
            " :invalid-feedback="
              Object.prototype.hasOwnProperty.call(item, 'invalid_feedback')
                ? item.invalid_feedback
                : ''
            ">
            <b-form-radio-group :id="item.label_for" v-model="item.model.$model" :options="item.options" :name="item.label_for" :state="
                Object.prototype.hasOwnProperty.call(item, 'state')
                  ? item.state
                  : null
              "></b-form-radio-group>
          </b-form-group>
        </template>

        <!--percentage-->
        <!--<b-form-group label-class="align-self-center pr-md-0 pr-lg-4 text-black pt-0" label-for="default_expense_percentage_to_pay" label-cols-lg="3" label-cols-xl="3" label-align-lg="right" class="pt-md-4" :label="$t(formLabel + 'default_expense_percentage_to_pay')">
          <percentage-input slider :min="0" :max="100" v-model="default_expense_percentage_to_pay" />
        </b-form-group>-->
        <b-col cols="12" class="float-left px-0">
          <div class="clearfix my-2 float-right mx-0 px-0">
            <b-button type="submit" size="sm" variant="primary" class="ml-3">{{
                !id ? $t("common.save") : $t("common.update")
              }}</b-button>
          </div>
          <div class="clearfix my-2 float-right mx-0 px-0">
            <b-button @click="close" size="sm" variant="outline-danger" class="ml-3">{{ $t("common.cancel") }}</b-button>
          </div>
        </b-col>
      </form>
    </template>
    <div class="bordered-table mt-3">
      <b-row no-gutters>
        <b-col class="col-12 col-md-6">
          <h2 class="text-gray">{{ $t(translationPath + "table_title") }}</h2>
        </b-col>
        <b-col class="col-12 col-md-6">
          <b-link class="btn btn-xs btn-secondary float-right mb-2 mb-md-0" href="javascript:void(0)" @click="displayForm" v-if="!id">
            <i class="flaticon stroke plus"></i>
            {{ $t(translationPath + "button.add_person") }}
          </b-link>
          <b-link v-if="!id && persons.length > 1 && !isExpensePercentageChangingEnabled" class="btn btn-xs btn-secondary float-right mb-2 mr-2 mb-md-0" href="javascript:void(0)" @click="isExpensePercentageChangingEnabled = true">
            {{ $t(translationPath + "button.change_distribution") }}
          </b-link>
        </b-col>
        <b-col class="col-12">
          <i18n :path="translationPath + 'table_description'" tag="p">
            <b-link slot="split-expenses" :to="'/reports/performance/split-expenses'" target="_blank">{{
                $t(translationPath + "table_description_split_expenses")
              }}</b-link>
          </i18n>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-table
          :items="persons"
          :fields="fields"
          :busy="loading"
          stacked="md"
          class="spirecta-simple-table mb-0"
          :emptyText="$t(translationPath + 'empty_text')"
          responsive
          striped
          show-empty
        >
          <template v-slot:head(actions)>
            &nbsp;
          </template>
          <!--loader-->
          <template v-slot:table-busy>
            <loader />
          </template>

          <template v-slot:cell(is_child)="row">
            <template v-if="row.value">{{ $t('common.yes') }}</template>
            <template v-else>{{ $t('common.no') }}</template>
          </template>

          <template v-slot:cell(default_expense_percentage)="row">
            <template v-if="!isExpensePercentageChangingEnabled">{{ row.value }}</template>
            <template v-else>
              <percentage-input :min="0" :max="100" :input-class="isExpensePercentageShowValidation ? expensePercentageInputClass : ''" v-model="row.item.default_expense_percentage_to_save" @input="onExpensePercentageInput" />
            </template>
          </template>

          <!--actions-->
          <template v-slot:cell(actions)="row">
            <b-button
              @click="editDetails(row.item)"
              :title="$t('common.edit')"
              class="plain-btn action-button-row mr-2"
            >
              <i class="flaticon solid edit-3 text-success"></i
            ></b-button>
            <b-button
              @click="deleteDetails(row.item)"
              :title="$t('common.delete')"
              class="plain-btn action-button-row"
            >
              <i class="flaticon solid trash-3 text-danger"></i
            ></b-button>
          </template>
        </b-table>
        <b-row no-gutters v-if="isExpensePercentageChangingEnabled" class="mt-2 w-100">
          <b-col>
            <b-link class="btn btn-xs btn-primary float-right mb-2 mb-md-0" href="javascript:void(0)" @click="onEditExpensePercentageSave">
              {{ $t("common.save") }}
            </b-link>
            <b-link class="btn btn-xs btn-outline-secondary float-right mb-2 mr-2 mb-md-0" href="javascript:void(0)" @click="onEditExpensePercentageCancel">
              {{ $t("common.cancel") }}
            </b-link>
          </b-col>
        </b-row>
      </b-row>
    </div>
    <!--Delete modal-->
    <delete-modal
      @on-delete-cancel="cancelDelete"
      @on-delete-confirm="successDelete"
      ref="deletePerson"
      :heading="deleteTitle"
      :message="$t(translationPath + 'modal.message')"
    />
  </div>
</template>

<script>
/* eslint-disable camelcase */
import DeleteModal from '@/components/modals/DeleteModal'
import PercentageInput from '@/components/common/PercentageInput'
import { required, minLength, helpers } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import axios from 'axios'
import Datepicker from 'vuejs-datepicker'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'
import Loader from '@/components/common/Loader'

const regCheckFormat = new RegExp(/^\d{4}$/)

const charactersOnly = value =>
  function (value) {
    const RegCheck = new RegExp(/^([a-zA-ZäåöÅÄÖ -])*$/)
    return value ? RegCheck.test(value) : true
  }

const validDateFormat = helpers.regex('validDateFormat', regCheckFormat)

export default {
  name: 'HouseholdSettings',
  components: { PercentageInput, Datepicker, Loader, DeleteModal },
  data () {
    return {
      translationPath: 'settings.tab_household.',
      show_add_form: false,
      isExpensePercentageChangingEnabled: false,
      isExpensePercentageShowValidation: false,
      expensePercentageInputClass: '',
      first_name: null,
      last_name: null,
      dob: null,
      sex: 'male',
      id: null,
      is_child: 0,
      is_spouse: 0,
      is_married: 1,
      is_living_together: 1,
      loading: true,
      show_in_split_finances: 1,
      // default_expense_percentage_to_pay: 50,
      disabledDates: {
        from: new Date()
      },
      startWeekByMonday:
        Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') &&
        process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY
          ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true'
          : false,
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      },
      checkBoxFields: [
        'show_in_split_finances',
        'is_living_together',
        'is_married',
        'is_spouse',
        'is_child'
      ],
      persons: [],
      deleteItem: {}
    }
  },
  validations: {
    first_name: {
      required,
      minLength: minLength(2),
      validCharacters: charactersOnly()
    },
    last_name: {
      validCharacters: charactersOnly()
    },
    dob: { required, validDate: validDateFormat() },
    sex: { required },
    is_child: { required },
    is_spouse: { required },
    is_married: { required },
    is_living_together: { required },
    show_in_split_finances: { required }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    fields () {
      return [
        { key: 'firstname', label: this.$t(this.formLabel + 'first_name'), sortable: true },
        { key: 'lastname', label: this.$t(this.formLabel + 'last_name'), sortable: true },
        { key: 'birth_year', label: this.$t(this.translationPath + 'birth_year'), sortable: true },
        { key: 'show_in_split_finances', label: this.$t(this.formLabel + 'show_in_split_finances'), sortable: true, formatter: 'boolFormatter' },
        { key: 'default_expense_percentage', label: this.$t(this.translationPath + 'default_expense_percentage'), sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ]
    },
    deleteTitle () {
      if (!this.deleteItem || Object.keys(this.deleteItem).length === 0) { return null }
      const personFname = Object.prototype.hasOwnProperty.call(this.deleteItem, 'firstname') !== -1 ? this.deleteItem.firstname : ''
      const personLname = Object.prototype.hasOwnProperty.call(this.deleteItem, 'lastname') !== -1 && this.deleteItem.lastname ? this.deleteItem.lastname : ''
      const personName = (personFname + ' ' + personLname).trim()
      return this.$t(this.translationPath + 'modal.heading', {
        person_name: personName
      })
    },
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    },
    sexOptions () {
      return [
        {
          text: this.$t(this.translationPath + 'form.sex.male'),
          value: 'male'
        },
        {
          text: this.$t(this.translationPath + 'form.sex.female'),
          value: 'female'
        },
        {
          text: this.$t(this.translationPath + 'form.sex.other'),
          value: 'other'
        }
      ]
    },
    yesNoOptions () {
      return [
        { text: this.$t(this.translationPath + 'form.yes'), value: 1 },
        { text: this.$t(this.translationPath + 'form.no'), value: 0 }
      ]
    },
    formLabel () {
      return this.translationPath + 'form.labels.'
    },
    formErrors () {
      return this.translationPath + 'form.errors.'
    },
    yesNoFields () {
      const labelClass = 'align-self-center pr-md-0 pr-lg-4 text-black pt-0'
      const classObject = {
        is_spouse: 'align-self-center pr-md-0 pr-lg-4 text-black',
        is_married: 'align-self-center pr-md-0 pr-lg-4 text-black'
      }
      const fields = []
      this.checkBoxFields.map(item => {
        fields.push({
          label: this.$t(`${this.formLabel}${item}`),
          label_class: Object.prototype.hasOwnProperty.call(classObject, item)
            ? classObject[item]
            : labelClass,
          label_for: item,
          model: this.$v[item],
          options: this.yesNoOptions,
          state: this.$v[item].$dirty ? !this.$v[item].$invalid : null,
          invalid_feedback:
            this.$v[item].$dirty && this.$v[item].$invalid
              ? this.$t(this.formErrors + 'checkbox_required')
              : ''
        })
      })
      return fields
    },
    invalidFirstFeedback () {
      if (this.$v.first_name.$dirty && this.$v.first_name.$invalid) {
        if (!this.$v.first_name.required) {
          return this.$t(this.formErrors + 'firstname_required')
        } else if (!this.$v.first_name.minLength) {
          return this.$t(this.formErrors + 'firstname_minlength')
        } else if (!this.$v.first_name.validCharacters) {
          return this.$t(this.formErrors + 'firstname_invalid')
        }
        return ''
      }
      return ''
    },
    firstNameState () {
      if (this.$v.first_name.$dirty) {
        return !this.$v.first_name.$invalid
      }
      return null
    },
    invalidLastFeedback () {
      if (this.$v.last_name.$dirty && this.$v.last_name.$invalid) {
        if (!this.$v.last_name.validCharacters) {
          return this.$t(this.formErrors + 'lastname_invalid')
        }
        return ''
      }
      return ''
    },
    lastNameState () {
      if (this.$v.last_name.$dirty) {
        return !this.$v.last_name.$invalid
      }
      return null
    },
    invalidDobFeedback () {
      if (this.$v.dob.$dirty && this.$v.dob.$invalid) {
        if (!this.$v.dob.required) {
          return this.$t(this.formErrors + 'dob_required')
        } else if (!this.$v.dob.validDate) {
          return this.$t(this.formErrors + 'dob_invalid')
        } else if (!this.$v.dob.greater) {
          return this.$t(this.formErrors + 'dob_greater')
        } else {
          return ''
        }
      }
      return ''
    },
    dobState () {
      if (this.$v.dob.$dirty) {
        return !this.$v.dob.$invalid
      }
      return null
    }
  },
  created () {
    this.loadPersons()
  },
  methods: {
    deleteDetails (item) {
      this.close()
      this.deleteItem = item
      this.$refs.deletePerson.show()
    },
    cancelDelete () {
      this.deleteItem = {}
    },
    editDetails (details) {
      const {
        id,
        firstname,
        lastname,
        birth_year,
        sex,
        is_child,
        is_spouse,
        is_married,
        is_living_together,
        show_in_split_finances
        // default_expense_percentage
      } = details
      this.id = id || null
      this.first_name = firstname
      this.last_name = lastname
      this.sex = sex
      this.dob = birth_year + '-01-01'
      this.is_child = is_child || 0
      this.is_spouse = is_spouse || 0
      this.is_married = is_married || 0
      this.is_living_together = is_living_together || 0
      this.show_in_split_finances = show_in_split_finances || 0
      // this.default_expense_percentage_to_pay = default_expense_percentage || 0
      this.$v.$reset()
      this.show_add_form = true
    },
    boolFormatter (value) {
      return value
        ? this.$t(this.translationPath + 'form.yes')
        : this.$t(this.translationPath + 'form.no')
    },
    async loadPersons () {
      return new Promise((resolve, reject) => {
        this.loading = true
        axios
          .get(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/persons`)
          .then(response => response.data.data)
          .then(response => {
            this.persons = response
            this.persons.map(person => {
              person.default_expense_percentage_to_save = person.default_expense_percentage
            })
            this.loading = false
            resolve(this.persons)
          })
          .catch(err => {
            console.error(err)
            this.loading = false
            reject(err)
          })
      })
    },
    displayForm () {
      this.show_add_form = true
    },
    close () {
      this.show_add_form = false
      this.id = null
    },
    dateFormatter (date) {
      return this.$moment(date).format('YYYY')
    },
    reset () {
      const properties = ['first_name', 'last_name', 'dob', 'id']
      properties.map(item => {
        this.$set(this, item, null)
      })
      this.checkBoxFields.map(item => {
        this.$set(this, item, 0)
      })
      this.$set(this, 'sex', 'male')
      // this.$set(this, 'default_expense_percentage_to_pay', 10)
      this.$v.$reset()
    },
    successDelete () {
      axios
        .delete(
          `${process.env.VUE_APP_ROOT_API}/charts-of-accounts/persons/${this.deleteItem.id}`
        )
        .then(response => {
          this.$bvToast.toast(
            this.$t(this.translationPath + 'alert.person_delete_success'),
            {
              title: this.$t(this.translationPath + 'alert.heading'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
          this.$refs.deletePerson.hide()
          this.deleteItem = {}
          this.onEditExpensePercentageCancel()
          this.loadPersons()
            .then(() => {
              this.increaseTotalExpenseDistributionEvenly()
            })
        })
        .catch(err => {
          console.warn(err)
          this.$bvToast.toast(
            this.$t(this.translationPath + 'alert.person_delete_failed'),
            {
              title: this.$t(this.translationPath + 'alert.heading'),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
        })
    },

    /**
     * Automatically incriease expense destributions value proportionally
     * between persons if total sum of percentages is not equal 100%.
     * It is useful when one of person is deleted. So percentages left
     * from this person will be proportionally distributed between others.
     */
    increaseTotalExpenseDistributionEvenly () {
      if (!this.persons.length) {
        return true
      } else if (this.persons.length === 1) {
        this.persons[0].default_expense_percentage_to_save = 100
        this.onEditExpensePercentageSave(false)
        return true
      }

      this.persons.map(person => {
        person.default_expense_percentage_to_save = person.default_expense_percentage
      })

      /**
       * Calculate current percentage sum and add persons for who we
       * will do increasons. Persons with 0% will not be affected.
       */
      let sum = 0
      const personsToDistribute = {}
      this.persons.map((person, index) => {
        sum += person.default_expense_percentage_to_save
        if (person.default_expense_percentage_to_save) {
          personsToDistribute[index] = person
        }
      })
      if (!sum) {
        this.persons.map((person, index) => {
          personsToDistribute[index] = person
        })
      }

      /**
       * Search a person with mimimum value. Store this value and person index.
       * We will use it as base for calculating other persons coeficients.
       */
      const delta = 100 - sum
      let minValuePerson = 101
      let minValuePersonIndex = -1
      for (const i in personsToDistribute) {
        if (personsToDistribute[i].default_expense_percentage_to_save < minValuePerson) {
          minValuePerson = personsToDistribute[i].default_expense_percentage_to_save
          minValuePersonIndex = i
        }
      }

      /**
       * Calculate person coeficients according to their current value.
       */
      let k = 1 // total coeficient
      const personsCoeficients = {}
      for (const i in personsToDistribute) {
        if (i === minValuePersonIndex) {
          personsCoeficients[i] = 1
          continue
        }

        personsCoeficients[i] = personsToDistribute[i].default_expense_percentage_to_save / personsToDistribute[minValuePersonIndex].default_expense_percentage_to_save
        k += personsCoeficients[i]
      }

      /**
       * Calculate multiplier. We will multiply it to each person coeficient.
       */
      const multiplier = delta / k

      /**
       * Finally, increase persons percentages.
       */
      for (const i in personsToDistribute) {
        personsToDistribute[i].default_expense_percentage_to_save += Math.round(multiplier * personsCoeficients[i])
      }

      /**
       * We should be sure that sum should be 100 after rounding values.
       * If not - we do a small correction.
       */
      let checkSum = 0
      for (const i in personsToDistribute) {
        checkSum += personsToDistribute[i].default_expense_percentage_to_save
      }
      if (checkSum !== 100) {
        const anomaly = 100 - checkSum
        if (anomaly > 0) {
          personsToDistribute[minValuePersonIndex].default_expense_percentage_to_save += anomaly
        } else {
          let maxValuePerson = 0
          let maxValuePersonIndex = -1
          for (const i in personsToDistribute) {
            if (personsToDistribute[i].default_expense_percentage_to_save > maxValuePerson) {
              maxValuePerson = personsToDistribute[i].default_expense_percentage_to_save
              maxValuePersonIndex = i
            }
          }
          personsToDistribute[maxValuePersonIndex].default_expense_percentage_to_save += anomaly
        }
      }

      this.onEditExpensePercentageSave(false)
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const postObject = {
          coa_id: this.currentCOA.id,
          firstname: this.first_name,
          sex: this.sex,
          lastname: this.last_name || '',
          birth_year: this.$moment(this.dob).format('YYYY'),
          is_child: this.is_child,
          is_spouse: this.is_spouse,
          is_married: this.is_married,
          is_living_together: this.is_living_together,
          show_in_split_finances: this.show_in_split_finances
          // default_expense_percentage: this.default_expense_percentage_to_pay
        }
        this.$bvToast.toast('Saving ...', {
          title: this.$t(this.translationPath + 'alert.heading'),
          variant: 'info',
          solid: true,
          'auto-hide-delay': 3000
        })
        axios({
          url: `${process.env.VUE_APP_ROOT_API}/charts-of-accounts/persons${
            this.id ? '/' + this.id : ''
          }`,
          method: this.id ? 'put' : 'post',
          data: postObject
        })
          .then(response => {
            this.$bvToast.toast(
              this.id
                ? this.$t(this.translationPath + 'alert.person_updated_success')
                : this.$t(this.translationPath + 'alert.person_add_success'),
              {
                title: this.$t(this.translationPath + 'alert.heading'),
                variant: 'success',
                solid: true,
                'auto-hide-delay': 2000
              }
            )
            this.show_add_form = false
            this.loadPersons()
          })
          .catch(err => {
            console.warn(err)
            const { status } = err.response
            if (status === 406) {
              this.$bvToast.toast(
                this.$t(this.translationPath + 'alert.person_duplicate_error'),
                {
                  title: this.$t(this.translationPath + 'alert.heading'),
                  variant: 'danger',
                  solid: true,
                  'auto-hide-delay': 3000
                }
              )
            } else {
              this.$bvToast.toast(
                this.id
                  ? this.$t(
                    this.translationPath + 'alert.person_updated_failed'
                  )
                  : this.$t(this.translationPath + 'alert.person_add_failed'),
                {
                  title: this.$t(this.translationPath + 'alert.heading'),
                  variant: 'danger',
                  solid: true,
                  'auto-hide-delay': 3000
                }
              )
            }
          })
      }
    },
    onEditExpensePercentageCancel () {
      this.persons.map(person => {
        person.default_expense_percentage_to_save = person.default_expense_percentage
      })
      this.isExpensePercentageChangingEnabled = false
      this.isExpensePercentageShowValidation = false
    },
    onEditExpensePercentageSave (toastSuccess = true) {
      if (!this.isPersonsEditPercentagesValid()) {
        this.isExpensePercentageShowValidation = true
        this.$bvToast.toast(
          this.$t(this.translationPath + 'alert.person_percentages_sum_invalid'),
          {
            title: this.$t(this.translationPath + 'alert.heading'),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          }
        )
        return false
      }

      const putData = { persons: {} }
      this.persons.map(person => {
        putData.persons[person.id] = { default_expense_percentage: person.default_expense_percentage_to_save }
      })
      axios.put(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/persons-update-multiple`, putData)
        .then(response => {
          this.persons.map(person => {
            person.default_expense_percentage = person.default_expense_percentage_to_save
          })
          this.isExpensePercentageChangingEnabled = false
          this.isExpensePercentageShowValidation = false
          if (toastSuccess) {
            this.$bvToast.toast(
              this.$t(this.translationPath + 'alert.person_percentages_saved'),
              {
                title: this.$t(this.translationPath + 'alert.heading'),
                variant: 'success',
                solid: true,
                'auto-hide-delay': 3000
              }
            )
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    isPersonsEditPercentagesValid () {
      let sum = 0
      this.persons.map(person => {
        sum += person.default_expense_percentage_to_save
      })
      if (sum === 100) {
        return true
      }
      return false
    },
    onExpensePercentageInput () {
      if (this.isPersonsEditPercentagesValid()) {
        this.expensePercentageInputClass = 'is-valid'
      } else {
        this.expensePercentageInputClass = 'is-invalid'
      }
    }
  },
  watch: {
    show_add_form: function (value) {
      if (!value) {
        this.reset()
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/settings.scss";
#household-settings {
  table {
    th {
      border-top: none;
    }
  }
  .custom-control-inline {
    margin-top: 6px;
  }
  .formTitle {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px dashed #b5b5b5;
  }
  .action-button-row {
    padding: 0;
    border: none;
    line-height: normal;
    i {
      margin: 0;
      font-size: 13px;
    }
    &:hover {
      background: transparent;
    }
    &:active {
      background-color: transparent !important;
      border-color: transparent !important;
    }
    &:focus {
      background: transparent;
      box-shadow: none !important;
    }
  }
  .addPersonForm {
    border-bottom: 1px dashed #b5b5b5;
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
    padding-bottom: 15px;
  }
  .vdp-datepicker {
    .is_invalid {
      border-color: #ef5350;
    }
    .is_valid {
      border-color: #36b37e;
    }
  }
  .personTables {
    padding: 20px 20px 10px;
    margin-top: 15px;
    background: #fff;
  }
  .btn-primary.btn-xs {
    font-size: 12px;
  }
}
</style>
